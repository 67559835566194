import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';

@Injectable()
export class UtilsProvider {

  public loader: any;

  constructor(public loadingCtrl: LoadingController,
    public toastCtrl: ToastController,
    ) {
    console.log('Hello UtilsProvider Provider');
  }

  async presentLoading(message?) {
    if (!this.loader) {
      this.loader = await this.loadingCtrl.create({
        message: message || ""
      });
      await this.loader.present();
      await this.loader.onDidDismiss(() => {
        this.loader = null;
      });
    }
  }

  async stopLoading() {
    if (this.loader) {
      this.loader.dismiss()
      this.loader = null;
    }
  }

  async createToast(mess) {
    const toast = await this.toastCtrl.create({
      message: mess,
      duration: 3000,
      cssClass: 'mytoast'
    });
    toast.present();
  }



  
}
