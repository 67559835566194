import { Component } from '@angular/core';

import { Platform, ToastController, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import * as firebase from 'firebase';
declare var FirebasePlugin: any;
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { DataCollectorService } from './data-collector.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  allDAta:any=[];
  assignedTasksEmpty=[]
  actualDB:any=[];
  constructor(
      private platform: Platform,
      private splashScreen: SplashScreen,
      private statusBar: StatusBar,
      public toastCtrl: ToastController,
      public navCtrl: NavController,
      public localNotifications: LocalNotifications,
      public service: DataCollectorService,
      public http: HttpClient  
    ) {
    
    if(localStorage.getItem('appVersion')){
      if(localStorage.getItem('appVersion') < environment.appVersion){
        this.service.logout();  
      }
    }else{
      this.service.logout();
    }    
    this.initializeApp();

    // this.http.get('assets/icon/music.json').subscribe(data => {
    //   console.log(data, "filesssss check");
    //   this.allDAta = data
      // for (var key in data) {
      //   var getData = data[key];
      //   getData.key = key;
      //   this.allDAta.push(getData);
      // }
      // for(let i =0; i<this.allDAta.length; i++){
      //   if(this.allDAta[i] == "users" || this.allDAta[i] == "assignedTasks" || this.allDAta[i] == "chats" ||
      //   this.allDAta[i] == "groups" || this.allDAta[i] == "items" || this.allDAta[i] == "notifications" ||
      //   this.allDAta[i] == "sections" || this.allDAta[i] == "stages" || this.allDAta[i] == "stageUrls" ||
      //   this.allDAta[i] == "taskHistory" || this.allDAta[i] == "tasks" ){
      //     this.actualDB.push(data[i] , "get all data")
      //   }
      // }
      // console.log(this.allDAta , "getAll DATA")

      // for(let i =0; i<this.allDAta.length; i++){
      //   if(this.allDAta[i] === "assignedTasks" ){
      //     this.assignedTasksEmpty.push(this.allDAta[i])
      //   }else{
      //     this.actualDB.push(this.allDAta[i])
      //   }
      //   if(i == this.allDAta.length -1){
      //     console.log(this.actualDB , "DataBase")
      //     console.log(this.assignedTasksEmpty , "dummy data want to delete")
      //   }
      // }

    // })
  }
  user: any;
  initializeApp() {
    this.platform.ready().then(() => {
      this.notificationsMethods();
      this.statusBar.backgroundColorByHexString('#f1f1f1');
      this.statusBar.styleDefault();
      this.splashScreen.hide();



      console.log(localStorage.getItem('uid'))

      // localStorage.setItem("email", "andreaoathout@gmail.com");
      // localStorage.setItem("name", "Luke Oathout");
      // localStorage.setItem("uid", "gP5JqOQVw0erV47YeFbA1DWgXma2");
      // localStorage.setItem("user_logged_in", "true");
      // localStorage.setItem('userRole', "User/Student");


      // localStorage.setItem("email", "roheelmustafa123@gmail.com");
      // localStorage.setItem("name", "Roheel");
      // localStorage.setItem("uid", "XFtM6K4ht2c0oxVTAHMmvjT85E63");
      // localStorage.setItem("user_logged_in", "true");
      // localStorage.setItem('userRole', "User/Student");
    });
  }

  notificationsMethods() {
    if (this.platform.is("cordova")) {


      FirebasePlugin.grantPermission(function (hasPermission) {
        console.log("Permission was " + (hasPermission ? "granted" : "denied"));
      });

      FirebasePlugin.hasPermission(function (hasPermission) {
        console.log("Permission is " + (hasPermission ? "granted" : "denied"));
      });

      FirebasePlugin.getToken((token) => {
        // save this server-side and use it to push notifications to this device
        console.log(token);
        /*  alert(token); */

        if (localStorage.getItem("deviceToken") !== token) {
          localStorage.setItem("deviceToken", token);
          if (localStorage.getItem("uid")) {
            this.setUserToken(token);
          }
        }
      }, function (error) {
        console.error(error);
        /* alert("Error getting Token!!!!"); */
      });


      FirebasePlugin.onTokenRefresh((token) => {
        // save this server-side and use it to push notifications to this device
        console.log(token);
        console.log(localStorage.getItem("deviceToken"));

        if (localStorage.getItem("deviceToken") !== token) {
          localStorage.setItem("deviceToken", token);
          if (localStorage.getItem("uid")) {
            this.setUserToken(token);
          }
        }
      }, function (error) {
        console.error(error);
      });


      FirebasePlugin.onMessageReceived((message) => {
        console.log("Message type: " + message.messageType);
        console.log(message.tap);
        console.log(message);
        if (localStorage.getItem("notificationsCount")) {
          var notiCount = Number(localStorage.getItem("notificationsCount"));
          notiCount = notiCount + 1;
          localStorage.setItem("notificationsCount", String(notiCount));
        } else {
          var notiCount = 1;
          localStorage.setItem("notificationsCount", String(notiCount));
        }
        if (!message.tap) {
          this.localNotifications.schedule({
            id: 1,
            title: message.title,
            text: message.body,
            foreground: true,
            smallIcon: '/assets/imgs/appLogo11.png',
          });
        }
        if (message.chat) {
          this.showToastMsg();
        }


      })

    }
  }

  async showToastMsg() {
    const toast = await this.toastCtrl.create({
      message: 'You have received a new Message!',
      position: 'top',
      duration: 2000,
    });
    toast.present();
  }
  setUserToken(token) {
    var uid = localStorage.getItem("uid");
    firebase.database().ref().child(`users/${uid}/deviceTokens`)
      .once("value")
      .then(snapshot => {
        var tokens: Array<any> = snapshot.val() || [];

        if (tokens.indexOf(token) < 0) {
          tokens.push(token);
          firebase.database().ref().child(`users/${uid}/deviceTokens`).set(tokens);
        }
      })
      .catch(err => {
        console.log(err.message);
      })
  }
}
