import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LoadingController, NavController } from '@ionic/angular';
import * as firebase from 'firebase';
import { UtilsProvider } from 'src/providers/utils/utils';
import * as moment from 'moment';
import { Badge } from '@ionic-native/badge/ngx';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataCollectorService {

  currentDate: any = Number(new Date());
  public stdPractices: Array<any> = [];
  public section: any = {};
  public index: any;
  public tasks: Array<any> = [];
  public task: any;
  activeChat: any;
  activeIndex: any;
  public fooSubject = new Subject<any>();
  public loader: any;
  public baseUploadURL: any = "https://codexiatech.dev/musicPractice/upload.php";
  badgeCount: number;
  uid: any;
  automatedActiveTasks: any = [];
  currentTasks: any = [];
  user: any;
  activeStudent: any;
  currentStage: any;
  overallPercentage: any;
  pdfLink: any;

  mp3links: any = []
  showActiveModal: any = false;
  lastLessonComplete: any = false;
  getAllTasks: any = []
  finnalStage: any = [];

  tasksForPercentage: any = [];
  congrats:boolean = false;

  stages=[];
  sections=[];
  finalStages=[];
  stageInfo:any;

  constructor(public http: HttpClient, public loadingCtrl: LoadingController,
    public utilties: UtilsProvider,public navCtrl:NavController,
    public badge:Badge) {
    this.uid = localStorage.getItem('uid');
    if (localStorage.getItem('userRole') === 'User/Student' || localStorage.getItem('userRole') === 'Automated Student') {
      this.getCurrnetStudent();
    }
    // if (localStorage.getItem('userRole') === 'Teacher') {
    //   this.getStages();
    // }
    console.log(localStorage.getItem('uid'))
    // if(localStorage.getItem('uid')) 
      // this.getAssiugnedTasks(localStorage.getItem('uid'))
    // this.fetchData();
  }
  
  getStages(){
    let data:any;
    // this.utilties.presentLoading();
    firebase.database().ref('/stages').once('value',stages =>{
      console.log('stages res',stages.val());
      this.stages = stages.val();
      
        firebase.database().ref('/tasks').once('value',task =>{
          let res = task.val();
          for(var key in res){
            if(!res[key].isDeleted){
              res[key].key = key;
              this.tasks.push(res[key]);
            }
            // debugger;
          }

        }).then(res =>{
          console.log('tasks',this.tasks);
          let getStageTasks;
          
          this.stages.forEach(item =>{
            getStageTasks = this.tasks.filter(x => x.stage == item);
            this.sections = [];

            getStageTasks.forEach(stageTask =>{
              let checkSection =  this.sections.find(x => x.sectionName ==  stageTask.section);
              
              if(!checkSection){
                // debugger;
                let getSectionTasks = getStageTasks.filter(x => x.section == stageTask.section);
                
                let data = {
                  sectionName: stageTask.section,
                  tasks: getSectionTasks
                }
                this.sections.push(data);
              }
            })
            
            
            let data ={
              stageName: item,
              sections: this.sections
            }
            this.finalStages.push(data);
            // debugger;
          })


        })

    }).then(res =>{
      console.log('stage data',this.finalStages);
      
    })
  }

  getAssiugnedTasks(uid) {
  //  debugger
    this.uid = uid;
    // debugger;
    this.finnalStage = []
    // debugger;
    console.log('assignedTasks/' + localStorage.getItem('uid'))
    firebase.database().ref('assignedTasks/' + uid).once('value', (snapshot) => {
      // debugger;
      this.getAllTasks = []
      console.log(snapshot.val(), "alltasks")
      if(snapshot.val()){
        var data = snapshot.val().tasks;
        // var data = snapshot.val().tasks.sort((n1,n2) => n1.task.sectionOrder - n2.task.sectionOrder);
        // debugger;
        for (let keys in data) {
          // debugger;
          if (data[keys].task && !data[keys].task.isDeleted) {
            var taskDetails: any = {}
            taskDetails = data[keys];
            taskDetails.key = keys;
            taskDetails.section = data[keys].task.section
            taskDetails.task.assignedTaskKey = keys;
            this.getAllTasks.push(taskDetails);
            this.getAllTasks.sort((n1,n2) => n1.task.sectionOrder - n2.task.sectionOrder);
          }
        };
        console.log('get all tasks',this.getAllTasks)
        // debugger;

      }
      else{
        this.utilties.stopLoading();
        firebase.database().ref('stageUrls/').orderByChild("stageName").equalTo(this.user.assignedStage).once('value', (snapshotStage) => {
          console.log(snapshotStage.val(), "else stages")
          for(let key in snapshotStage.val()){
            this.stageInfo = snapshotStage.val()[key];
            
          }
          console.log('stage data',this.stageInfo);
        })
      }




      this.tasksHistory().then(resultHistory => {
        this.tasksForPercentage = resultHistory;
        console.log(resultHistory, "historysssssssssssss");

        this.taskSorting(this.getAllTasks).then(res => {
          this.getAllTasks = res;
          console.log(this.getAllTasks, "ressssssssss")

          
          firebase.database().ref('stageUrls/').orderByChild("stageName").equalTo(this.getAllTasks[0].task[0].stage).once('value', (snapshotStage) => {
            console.log(snapshotStage.val(), "test stages")

            var stData = snapshotStage.val();
            console.log('stData',stData)
            if(!stData) {
              var obj = {
                coins: 10,
                lessonMsg: "",
                stageName: this.getAllTasks[0].task[0].stage,
                stageOtherName: "",
                stageUrl: ""
              };

              var key = firebase.database().ref('stageUrls').push(obj).key;
              stData = {};
              stData[key] = obj;

              
            }

            for (let key in stData) {
              var stageInfo: any = {};
              console.log(key, "keyyyyyyy")
              // debugger;
              stageInfo = stData[key];
              if(!stageInfo){
                debugger;
              }
              stageInfo.key = key;
              var sectionData = {
                stage: stData[key].stageName,
                tasks: this.getAllTasks,
                stageInfo: stageInfo,
              }
              this.finnalStage[0] = sectionData;
              console.log(this.finnalStage,'finalStage');
              this.utilties.stopLoading();
              this.resourcesAndModel();
            }
          }).catch(err =>{
            console.log('err',err);
          })
        }).catch(err =>{
          if(this.stageInfo.completedBy[this.uid]?.endDate && this.currentDate > this.stageInfo.completedBy[this.uid]?.endDate) {
            this.showActiveModal = true
            if (localStorage.getItem('userRole') === 'Automated Student') {
              this.nextStageGetting()
              console.log("last date done")
              debugger;
            }
          }
        })

      })
    })
  }
  completedTask: any = []
  tasksHistory() {
    return new Promise<any>((resolve, reject) => {
      this.completedTask = [];
      firebase.database().ref().child('taskHistory/' + this.uid)
        .once('value', (snapshot) => {
          // debugger;
          if(snapshot.val()){
            var completedTasks = snapshot.val();
            console.log('completed tasks',completedTasks)
            for (var key in completedTasks) {
              if(key == moment().format("DD-MM-YYYY")){
                var tasks = completedTasks[key];
                console.log('key tasks',key)
                for (var key1 in tasks) {
                  var task = tasks[key1];
                  if (task.isCompleted) {
                    var idx = this.getAllTasks.findIndex(tasks => tasks.taskId == key1)
                    if (idx > -1) {
                      var task: any = {};
                      task = this.getAllTasks[idx];
                      task.task.isCompleted = true;
                      this.getAllTasks[idx] = task
                    }
                    this.completedTask.push(key1);
                  }
                }
              }
              resolve(this.getAllTasks)
            // console.log(this.completedTask, "completedTasks")
            }
          }else{
            resolve(this.getAllTasks);
            this.utilties.stopLoading();
          }
        }, err => {
          reject(err)
        })
    })
  }



  resourcesAndModel() {
    var resources = this.finnalStage[0]?.stageInfo?.resources;
    console.log('stage info',this.finnalStage[0]?.stageInfo)
    console.log("ok ",)
    for (let k = 0; k < resources?.length; k++) {
      if (resources[k].type == "mp3") {
        this.mp3links.push(resources[k]);
        console.log("last date mp3", this.mp3links)
      }
    }
    console.log('current user',this.currentDate > this.finnalStage[0].stageInfo.completedBy[this.uid]?.endDate);
    // debugger;

    if (this.finnalStage[0].stageInfo.completedBy && this.currentDate > this.finnalStage[0].stageInfo.completedBy[this.uid]?.endDate) {
      // debugger;
      console.log('last lesson complete',this.lastLessonComplete);
      this.showActiveModal = true
      if (localStorage.getItem('userRole') === 'Automated Student') {
        this.nextStageGetting();
    
      }
    }
  }

  taskSorting(allTask) {
    // continueeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee
    return new Promise<any>((resolve, reject) => {
      var newlist: any = [];
      allTask.forEach(x => {
        var idx = newlist.findIndex(obj => obj.section == x.task.section);
        x.section = x.task.section
        var data = JSON.parse(JSON.stringify(x));
        data.task = [data.task]
        if (idx > -1) {
          newlist[idx].task.push(x.task)
        }
        else {
          newlist.push(data)
        }
        // debugger;
      })
      console.log(newlist, "newlistArray");
      // debugger;

      this.utilties.stopLoading()
      resolve(newlist)
    })
  }


  // if the day is wednesday

  nextStage: any = []
  nexttasks: any = [];

  nextStageGetting() {
    let strName;
    if(this.finnalStage.length > 0){
      strName = this.finnalStage[0].stage.split(" ")
    }else{
      strName = this.stageInfo.stageName.split(" ");
    }
    console.log(strName[1])
    var num: number = 0;
    num = parseInt(strName[1]) + 1;
    var str: string = strName[0] + " " + num;
    console.log(str, "stagesss")
    // debugger;
    firebase.database().ref('stageUrls/').orderByChild("stageName").equalTo(str).once('value', (snapshotStage) => {
      console.log(snapshotStage.val(), "next stages data")
      // debugger;
      if (snapshotStage.val()) {
        for (let key in snapshotStage.val()) {
          var stage: any = {};
          stage = snapshotStage.val()[key];
          stage.key = key
          // debugger;
          this.nextStage.push(stage);
          console.log('next stage 1',this.nextStage);
        }
        
        firebase.database().ref('tasks/').orderByChild("stage").equalTo(str).once('value', (snapshotTasks) => {
          console.log(snapshotTasks.val(), "stages....tasks")
          for (let keys in snapshotTasks.val()) {
            var task: any = {};
            task = snapshotTasks.val()[keys];
            task.key = keys
            task.taskId = keys
            this.nexttasks.push(task)
          }
          var sectionData = {
            stage: str,
            tasks: this.nexttasks,
            stageInfo: this.nextStage[0],
          }
          this.nextStage = sectionData
          console.log(sectionData)

        })
      } else {
        console.log("noStage")
      }

    }, err => {
      console.log(err)
    })
  }



  // delData:any=[]
  // fetchData() {
  //   var ref = firebase.database().ref();
  // ref.limitToFirst(20).once('value', (snapshot) => {
  //   console.log(snapshot.val(), "getdata");
  //   var data = snapshot.val();
  //   for (var key in data) {
  //     if(data[key] == "assignedTasks"){
  //       var keysForDelete = key;
  //       this.delData.push(keysForDelete)
  //     }
  //     else{
  //       console.log(data[key])
  //     }
  //   }
  //   console.log(this.delData)
  //   // this.deleteDatafromDB()   
  // });

  // }

  // deleteDatafromDB(){
  //   var updates:any={}
  //   console.log("start runiing")
  //   for(let i=0; i<this.delData.length; i++){
  //      updates[this.delData[i]]= null;


  //     if(i == this.delData.length -1){
  //       console.log(updates);
  //       firebase.database().ref().update(updates).then((res) => {
  //       console.log("allDetelted  completed",res)
  //       },err=>{
  //         console.log(err)
  //       })
  //     }
  //   }
  // }


  getCurrnetStudent() {
    const self = this;
    const uid = localStorage.getItem('uid');
    firebase.database().ref().child(`/users/${uid}`)
      .on('value', (snapshot) => {
        const user = snapshot.val();
        this.user = user;
        if(this.user.canSkipKajabi == false || this.user.canSkipKajabi == 'false'){
          this.logout();
        }
        console.log('user',this.user)
        self.badgeCount = user.badgeCount || 0;
      });
  }
  
  logout() {
    var token = localStorage.getItem('deviceToken');
    this.removeCurrentTokenFromUser();
    localStorage.clear();
    localStorage.setItem('appVersion',environment.appVersion);
    var user = firebase.auth().currentUser;
    // debugger;
    if (user != null) {
      firebase.auth().signOut().then(() => {
        this.automatedActiveTasks=[];
        this.user=null;
        localStorage.setItem("userLoggedIn", "false");
        localStorage.setItem('deviceToken', token);
        localStorage.setItem('uid', "");
        // debugger;
        //self.service.publishSomeData(true);
        this.finnalStage = []
        this.getAllTasks = []
        this.completedTask = []
        this.nextStage = []
        this.nexttasks = [];
        this.navCtrl.navigateRoot(['/login']);
        this.badge.clear();
      }
      , (error) => {
        this.utilties.createToast(error);
      });
    } else {
      this.navCtrl.navigateRoot(['/login']);
    }
  }

  removeCurrentTokenFromUser() {
    var uid = localStorage.getItem("uid");
    var token = localStorage.getItem("deviceToken");
    firebase.database().ref().child(`users/${uid}/deviceTokens`)
      .once("value")
      .then(snapshot => {
        var tokens: Array<any> = snapshot.val() || [];

        var index = tokens.indexOf(token);

        if (index >= 0) {
          tokens.splice(index, 1);

          if (tokens.length > 0) {
            firebase.database().ref().child(`users/${uid}/deviceTokens`).set(tokens);
          } else {
            firebase.database().ref().child(`users/${uid}/deviceTokens`).set(null);
          }
        }
      })
      .catch(err => {
        console.log(err.message);
      })
  }

  publishSomeData(data: any) {
    this.fooSubject.next(data);
  }

  uploadVideoFile(formData: FormData) {
    return this.http.post(this.baseUploadURL, formData).toPromise();
  }

  async presentLoading(message?) {
    if (!this.loader) {
      this.loader = await this.loadingCtrl.create({
        message: message || ""
      });
      // debugger;

      this.loader.present();

      // const { role, data } = await this.loader.onDidDismiss();
    }
  }

  stopLoading() {

    if (this.loader) {
      this.loader.dismiss();
      this.loader = null;
    }
    // debugger;
  }

  sendNewNotification(title, message, deviceToken, data?, badgeCount?) {
    for (var i = 0; i < deviceToken.length; i++) {

      // firebase.app().functions("asia-northeast1").httpsCallable('sendNotification');
      var addMessage = firebase.functions().httpsCallable('sendNotification');
      addMessage({
        title: title,
        message: message,
        data: data || "",
        deviceToken: deviceToken[i],
        badgeCount: badgeCount || ''
      }).then((result) => {
      }, (error) => {
        alert(error);
      });
    }
  }

  saveNotificationToFirebase(uid, message, title, data?) {
    var postData = {
      title: title,
      message: message,
      user: uid,
      timestamp: Number(new Date()),
      data: data || ""
    };
    firebase.database().ref().child("notifications/" + uid).push(postData).key;
  }





  forallPercentage() {


    console.log('automated tasks',this.automatedActiveTasks);
    // debugger;
    console.log('task for percentage',this.tasksForPercentage);
    var filterCount = this.tasksForPercentage.filter(tasks => tasks.task.isCompleted)
    console.log('filterCount',filterCount);
    var allPers = 0;
    if (filterCount.length != 0) {
      allPers = filterCount.length
    }
    this.overallPercentage = allPers / this.tasksForPercentage.length * 100;
    console.log(this.overallPercentage, filterCount.length, "percentagessssss , line 350")


    var newTasksPErcentage = [];
    console.log('automated length',this.automatedActiveTasks.length)
    // debugger;

    for (let i = 0; i < this.automatedActiveTasks.length; i++) {
      // debugger;
      var automatedCountinue: any = {};
      var filterCountForAll = this.automatedActiveTasks[i].task.filter(tasks => tasks.isCompleted)
      console.log(filterCountForAll);
      var countPer = 0;
      if (filterCountForAll.length != 0) {
        countPer = filterCountForAll.length;
      }
      var percentageCounter = 0;
      console.log(countPer, this.automatedActiveTasks[i].task.length)
      // debugger;
      percentageCounter = countPer / this.automatedActiveTasks[i].task.length * 100;
      automatedCountinue = this.automatedActiveTasks[i];
      automatedCountinue.percentage = percentageCounter;
      automatedCountinue.tasks = this.automatedActiveTasks[i].task;
      newTasksPErcentage.push(automatedCountinue);
      if (i == this.automatedActiveTasks.length - 1) {
        console.log(newTasksPErcentage)
        this.automatedActiveTasks = [];
        this.automatedActiveTasks = newTasksPErcentage;
      }
    }

  }
  
  // async UploadImage(image) {
  //   return new Promise<any>(async (resolve, reject) => {
  //     const base64Response = await fetch(image);
  //     const blob = await base64Response.blob();
  //     var data: FormData = new FormData();
  //     data.append('file', blob);
  //       this.postblob(data).then((res: any) => {
  //       resolve(res);
  //     },err=>{
  //       reject(err)
  //     })
  //   })

  // }
  
  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    return new Promise<any>((resolve, reject) => {
      var byteString = atob(dataURI.split(',')[1]);

      // separate out the mime component
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

      // write the bytes of the string to an ArrayBuffer
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      
      var bb = new Blob([ab]);
      resolve(bb);
      // return bb;
    })
  
  }

  

  
  
    // postblob(data: FormData) {
  //   return new Promise<any>((resolve, reject) => {
  //     this.Http.post(this.url, data).subscribe(res => {
  //       resolve(res)
  //     }, err => {
  //       console.log(err)
  //       reject(err)
  //     })

  //   })
  // }

}


