import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { DataCollectorService } from '../app/data-collector.service';
import { UtilsProvider } from '../providers/utils/utils';
import * as firebase from 'firebase';
import { HttpClientModule } from '@angular/common/http';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';
import { Vibration } from '@ionic-native/vibration/ngx';
import { Badge } from '@ionic-native/badge/ngx';
//import { SafePipe } from './safe.pipe';
import { StdHomePageModule } from './std-home/std-home.module';
import { AutomatedHomePageModule } from './automated-home/automated-home.module';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
 
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { Camera } from '@ionic-native/camera/ngx';
import { MediaCapture, MediaFile, CaptureError, CaptureImageOptions } from '@ionic-native/media-capture/ngx';
import { File } from '@ionic-native/file/ngx';

var firebaseConfig = {
  apiKey: "AIzaSyDtaTA4U3pQZjv6NHS6H4A_H4SbCvO1z_I",
  authDomain: "music-practice-partner-app.firebaseapp.com",
  databaseURL: "https://music-practice-partner-app.firebaseio.com",
  projectId: "music-practice-partner-app",
  storageBucket: "music-practice-partner-app.appspot.com",
  messagingSenderId: "806132141247",
  appId: "1:806132141247:web:125ae91119a471b0480a0a",
  measurementId: "G-T4W32W86KQ"
};
  ///// testing firebase ///////
// const firebaseConfig = {
//   apiKey: "AIzaSyCZfaNwlaoeSyaUiW_5em61jzH3TVc3G04",
//   authDomain: "sample-music-practice.firebaseapp.com",
//   databaseURL: "https://sample-music-practice-default-rtdb.firebaseio.com",
//   projectId: "sample-music-practice",
//   storageBucket: "sample-music-practice.appspot.com",
//   messagingSenderId: "389783238684",
//   appId: "1:389783238684:web:226bac04526b0c44df87d9"
// };
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

@NgModule({
  declarations: [AppComponent   ],
  entryComponents: [   ],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule,PdfViewerModule],
  providers: [
    DataCollectorService,
    StatusBar,
    UtilsProvider,
    SplashScreen,
    LocalNotifications,
    Vibration,
    Badge,
    NgxIonicImageViewerModule,
    InAppBrowser,
    NativeAudio,
    Camera,
    MediaCapture,
    File,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
