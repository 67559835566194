import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'std-home',
    loadChildren: () => import('./std-home/std-home.module').then(m => m.StdHomePageModule)
  },
  {
    path: 'std-tasks',
    loadChildren: () => import('./std-tasks/std-tasks.module').then(m => m.StdTasksPageModule)
  },
  {
    path: 'task-detail',
    loadChildren: () => import('./task-detail/task-detail.module').then( m => m.TaskDetailPageModule)
  },
  {
    path: 'add-section',
    loadChildren: () => import('./add-section/add-section.module').then(m => m.AddSectionPageModule)
  },
  {
    path: 'practice-plans/:student',
    loadChildren: () => import('./practice-plans/practice-plans.module').then(m => m.PracticePlansPageModule)
  },
  {
    path: 'pre-home',
    loadChildren: () => import('./pre-home/pre-home.module').then(m => m.PreHomePageModule)
  },
  {
    path: 'all-students',
    loadChildren: () => import('./all-students/all-students.module').then(m => m.AllStudentsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'inbox',
    loadChildren: () => import('./inbox/inbox.module').then( m => m.InboxPageModule)
  },
  {
    path: 'chat-screen',
    loadChildren: () => import('./chat-screen/chat-screen.module').then( m => m.ChatScreenPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'history',
    loadChildren: () => import('./history/history.module').then( m => m.HistoryPageModule)
  },
  {
    path: 'automated-home',
    loadChildren: () => import('./automated-home/automated-home.module').then( m => m.AutomatedHomePageModule)
  },
  {
    path: 'not-watched',
    loadChildren: () => import('./not-watched/not-watched.module').then( m => m.NotWatchedPageModule)
  },
  {
    path: 'forget-password',
    loadChildren: () => import('./forget-password/forget-password.module').then( m => m.ForgetPasswordPageModule)
  },
  {
    path: 'send-email',
    loadChildren: () => import('./send-email/send-email.module').then( m => m.SendEmailPageModule)
  },
  {
    path: 'std-new-home',
    loadChildren: () => import('./std-new-home/std-new-home.module').then( m => m.StdNewHomePageModule)
  },
  {
    path: 'store',
    loadChildren: () => import('./store/store.module').then( m => m.StorePageModule)
  },
  {
    path: 'purchased-items',
    loadChildren: () => import('./purchased-items/purchased-items.module').then( m => m.PurchasedItemsPageModule)
  },
  {
    path: 'student-detail',
    loadChildren: () => import('./student-detail/student-detail.module').then( m => m.StudentDetailPageModule)
  },
  {
    path: 'mp3play',
    loadChildren: () => import('./mp3play/mp3play.module').then( m => m.Mp3playPageModule)
  },
  {
    path: 'openpdf',
    loadChildren: () => import('./openpdf/openpdf.module').then( m => m.OpenpdfPageModule)
  },
  {
    path: 'assign-tasks',
    loadChildren: () => import('./assign-tasks/assign-tasks.module').then( m => m.AssignTasksPageModule)
  },
  {
    path: 'parents-control',
    loadChildren: () => import('./parents-control/parents-control.module').then( m => m.ParentsControlPageModule)
  },

  
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
